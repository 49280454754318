// Button.ts
import * as Phaser from 'phaser';

export interface Button extends Phaser.GameObjects.Text {
    OnClick: (callback: () => void) => void;
}

export function createButton(scene: Phaser.Scene, x: number, y: number, text: string, style: Phaser.Types.GameObjects.Text.TextStyle): Button {
    const button = scene.add.text(x, y, text, style) as Button;
    button.setInteractive({ useHandCursor: true });
    
    button.OnClick = (callback: () => void) => {
        button.on('pointerdown', callback);
    };
    
    return button;
}