import React, { useState, useEffect } from "react";
import {
	TonConnectButton,
	useTonConnectUI,
} from "@tonconnect/ui-react";
import "./WalletConnection.css";
import { Blastd } from "../../blastd/blastd";
import Story from "../Story/Story";
import { use } from "matter";

interface WalletConnectionProps {
	viewState: ViewState;
	tryAuth: (w: any) => void;
}

export type ViewState = "connect" | "pay";

const WalletConnection: React.FC<WalletConnectionProps> = ({ viewState, tryAuth }) => {
	const [processing, setProcessing] = useState(false);
	const [showStory, setShowStory] = useState(false);
	const [tonConnectUI] = useTonConnectUI();
	const blastd = Blastd.getInstance()

	useEffect(() => {
		if (viewState === "pay") {
			setShowStory(true)

			const cancel = setTimeout(() => {
				setShowStory(false)	
			}, 37 * 1000);

			return () => {
				clearTimeout(cancel)
			}
		}
	}, [])

	const handlePayButtonClick = async () => {
		setProcessing(true)
		try {
			await blastd.makePayment(tonConnectUI);
			await blastd.waitForPayment(tonConnectUI.wallet!!.account.address)
			tryAuth(tonConnectUI.wallet)
		} catch (error) {
			console.error("Transaction failed", error);
			setProcessing(false)
		}
	};

	if (showStory) {
		return (<Story />)
	}

	return (
		<div className="wallet-connection-container">
			<div className="wallet-connection-content">
				{(viewState === "connect" && !processing) && (
					<>
						<h2>Connect TON wallet</h2>
						<div>One time payment of 0.2 TON is required to access the game</div>
						<TonConnectButton />
					</>
				)}
				{(viewState === "pay" && !processing) && (
					<>
						<h2>Pay one time fee</h2>
						<p>Pay 0.2 TON to play Chicken Blastoff</p>
						<button id="pay-button" onClick={handlePayButtonClick}>
							<img
								src="https://cdn.chickenblaster.xyz/assets/images/ui/walletconnect/wallet.png"
								alt="Wallet"
							/>
							<span>Pay 0.2 TON</span>
						</button>
					</>
				)}
				{processing && (
					<>
						<h2>Processing Payment</h2>
						<p>Confirm transaction when prompted</p>
						<div className="loader"></div>
					</>
				)}
			</div>
		</div>
	);
};

export default WalletConnection;
