import React, { createContext, useContext, useEffect, useState } from 'react';

interface TelegramWebApp {
  ready: () => void;
  expand: () => void;
  close: () => void;
  shareUrl: (url: string) => void;
  // Add other methods you're using
}

interface TelegramContextType {
  webApp: TelegramWebApp | null;
}

const TelegramContext = createContext<TelegramContextType>({ webApp: null });

export const useTelegram = () => useContext(TelegramContext);

export const TelegramProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [webApp, setWebApp] = useState<TelegramWebApp | null>(null);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      setWebApp(app);
      app.ready();
    }
  }, []);

  return (
    <TelegramContext.Provider value={{ webApp }}>
      {children}
    </TelegramContext.Provider>
  );
};
