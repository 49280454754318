import React, { useState } from 'react';
import "./referLink.css";

interface ReferLinkProps {
  refs: [];
}

const ReferLink: React.FC<ReferLinkProps> = ({refs}) => {
  const [copyStatus, setCopyStatus] = useState<string>('');
  const tg = window.Telegram.WebApp;
  const referLink = "https://t.me/chickenblastbot?start=" + tg.initDataUnsafe.user.username;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(referLink);
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus(''), 2000); // Clear the status after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
      setCopyStatus('Failed to copy');
    }
  };

  const refsLength: Number = refs.length;

  return (
    <div className="refer-container">
      <div className="ref-top">
        <div className="refer-header">
          <h2>Invite Friends</h2>
          <h2>{refsLength == 1 ? "1 Referral" : refs.length + " Referrals"} </h2>
        </div>
        <div className="refer-grid">
          <button onClick={copyToClipboard} className="refer-link-button">
            {referLink}
          </button>
          {copyStatus && <span className="copy-status">{copyStatus}</span>}
        </div>
      </div>
      <div className="refcontent">Score 10% for friends invite and 2.5% from their referrals</div>
    </div>
  );
};

export default ReferLink;