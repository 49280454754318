import TonWeb from "tonweb";

export const Utils = {
  addressToTvmSlice: async (address: string): Promise<string> => {
    const cell = new TonWeb.boc.Cell();
    const userAddress = new TonWeb.utils.Address(address);
    cell.bits.writeAddress(userAddress);

    return (TonWeb.utils.bytesToBase64)(await cell.toBoc(false))
  },

  formatTime: (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
  },

  truncateAddress: (address: string) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  },
};