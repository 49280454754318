import { AssetType } from "./assets";

export class Powerup extends Phaser.Physics.Arcade.Sprite {
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, AssetType.Powerup);
        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.setVelocityY(100); // Make the powerup fall down
        console.log(`Powerup spawned at (${x}, ${y})`); // Add this line
    }

    update() {
        console.log(`Powerup position: (${this.x}, ${this.y})`);
    }

    consume() {
        console.log("powerup consumed");
        this.destroy();
    }
}
