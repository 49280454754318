import React, { useState, useEffect } from "react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { Blastd } from "../../blastd/blastd";
import "./Store.css";
import TonWeb from "tonweb";

interface StoreProps {
	activeStoreTab: string;
}

interface EnergyData {
	CurrentEnergy: number;
	NextRefillSeconds: number;
}

export interface StoreItem {
    id: number;
    name: string;
    image: string;
    price: number;
    type: 'ship' | 'lives';  // New field to distinguish between item types
}

const DEFAULT_SHIP: StoreItem = {
    id: -1,
    name: "Ghost",
    image: "player.png",
    price: 0,
    type: 'ship'
};

const LIVES_ITEM: StoreItem = {
    id: 5,
    name: "Extra Lives",
    image: "lives.png",
    price: 1000000000,  
    type: 'lives'
};

const Store: React.FC<StoreProps> = ({ activeStoreTab }) => {
	const [storeItems, setStoreItems] = useState<StoreItem[]>([DEFAULT_SHIP]);
	const [userItems, setUserItems] = useState<Number[]>([DEFAULT_SHIP.id]);
	const [activeItem, setActiveItem] = useState<StoreItem>(DEFAULT_SHIP);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [energy, setEnergy] = useState<EnergyData | null>(null);
	const blastd = Blastd.getInstance();
	const userFriendlyAddress =  useTonAddress();
	const [buying, setBuying] = useState(false);
	const [choosing, setChoosing] = useState(false);
	const [activeShip, setActiveShip] = useState(-1);
	const [tonconnect] = useTonConnectUI();

	useEffect(() => {
		fetchStoreItems();
		fetchEnergy();
	}, []);

	const selectItem = (item: StoreItem) => {
		if(!buying && !choosing) {
			setActiveItem(item);
		}
	}

	const fetchEnergy = async () => {
        try {
            const energyData = await blastd.fetchEnergy();
            setEnergy(energyData as EnergyData);
        } catch (error) {
            console.error("Error fetching energy:", error);
        }
    };

	const fetchStoreItems = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const fetchedItems = await blastd.getStoreItems();
			const allItems = [DEFAULT_SHIP, ...fetchedItems, LIVES_ITEM];
			setStoreItems(allItems);
			setActiveItem(DEFAULT_SHIP);
	
			const itemsOwned = await blastd.getUserItems(userFriendlyAddress);
			const newUserItems = [DEFAULT_SHIP.id, ...itemsOwned];
			setUserItems(newUserItems)
	
			setActiveShip(await blastd.getActiveShip())
		} catch (error) {
			console.error("Error in fetchStoreItems:", error);
			setError(`Failed to fetch store items: ${error instanceof Error ? error.message : String(error)}`);
			setStoreItems([DEFAULT_SHIP, LIVES_ITEM]);
		} finally {
			setIsLoading(false);
		}
	};

	const proceedToBuyItem = async (item: StoreItem) => {
		try {
			setBuying(true)
			await blastd.buyItem(tonconnect, item);
			const itemsOwned = await blastd.getUserItems(userFriendlyAddress);
			const newUserItems = [DEFAULT_SHIP.id, ...itemsOwned];
			setUserItems(newUserItems);
		}catch(error) {
			console.error("Error in proceedToBuyItem:", error);
			// setError(`Failed to buy item: ${error instanceof Error ? error.message : String(error)}`);
		}finally {
			setBuying(false);
			if (item.type === 'lives') {
                fetchEnergy();
			}
		}
	}

	const proceedToChooseItem = async (item: StoreItem) => {
		try {
			setChoosing(true)
			await blastd.setActiveShip(item.id);
			setActiveShip(item.id);
		}catch(error) {
			console.error("Error in proceedToChooseItem:", error);
			// setError(`Failed to choose item: ${error instanceof Error ? error.message : String(error)}`);
		}finally {
			setChoosing(false);
		}
	}

	if (isLoading) {
		return <div className="store-content">Loading...</div>;
	}

	if (error) {
		return (
			<div className="store-content">
				<p>Error: {error}</p>
				<button onClick={fetchStoreItems}>Retry</button>
			</div>
		);
	}

	if (storeItems.length === 0) {
		return <div className="store-content">No items available in the store.</div>;
	}

	return (
		<div className="store-content">
			<div className="store-grid">
				{activeItem && (
					<div className="active-item">
						<div className="shipcontainer">
							<img
								src={`https://cdn.chickenblaster.xyz/assets/images/ui/store/${activeItem.image}`}
								alt={activeItem.name}
							/>
							<div className="shadow"></div>
						</div>
						<h4>{activeItem.name}</h4>
						{activeItem.type === 'lives' ? (
                            <span className="lives-count">
                                Lives: {energy ? energy.CurrentEnergy : 'Loading...'}
                            </span>
                        ) : (
                            <span className="price">
                                {userItems.indexOf(activeItem.id) > -1 ? "Owned" : TonWeb.utils.fromNano(activeItem.price.toString()) + " TON"}
                            </span>
                        )}
						<ShipSelectionButton activeItem={activeItem} activeShip={activeShip} userItems={userItems!} buying={buying} buyItem={proceedToBuyItem} chooseItem={proceedToChooseItem} />
					</div>
				)}
				<div className="other-items">
					{storeItems.map((item) => (
						<div key={item.id} className="store-item" onClick={() => selectItem(item)}>
							{ (userItems.indexOf(item.id) === -1) && <img
								src={`https://cdn.chickenblaster.xyz/assets/images/ui/store/Lock.svg`}
								alt={"not owned"}
								className="store-lock"
							/> }
							<img
								src={`https://cdn.chickenblaster.xyz/assets/images/ui/store/${item.image}`}
								alt={item.name}
								className="store-image"
							/>
							{ userItems.indexOf(item.id) > -1 ? (
								<span></span>
							) : (
								<button className="other-buy-button" >
									{TonWeb.utils.fromNano(item.price.toString())} TON
								</button>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Store;

interface ShipProps {
	activeItem: StoreItem;
	activeShip: number;
	userItems: Number[];
	buying: boolean;
	buyItem: (item: StoreItem) => void;
	chooseItem: (item: StoreItem) => void;
}

const ShipSelectionButton: React.FC<ShipProps> = ({ activeItem, activeShip, userItems, buying, buyItem, chooseItem }) => {
    const isActiveShip = activeItem.id === activeShip;
    const isOwned = userItems.indexOf(activeItem.id) > -1;

    if (activeItem.type === 'lives') {
        return (
            <button className="buy-button" onClick={() => buyItem(activeItem)}>
                {buying ? "Buying..." : "Buy"}
            </button>
        );
    }

    if (isActiveShip) return null;

    if (isOwned) {
        return (
            <button className="buy-button" onClick={() => chooseItem(activeItem)}>
                Choose
            </button>
        );
    }
  
    return (
        <button className="buy-button" onClick={() => buyItem(activeItem)}>
            {buying ? "Buying..." : "Buy"}
        </button>
    );
};
  