import { AssetType } from "../assets";
import { GameState } from "../game-state";
import { MainScene } from "../../scenes/main";
import { Button, createButton } from "../../../components/button/Button";

export class ScoreManager {
  scoreText!: Phaser.GameObjects.Text;
  line1Text!: Phaser.GameObjects.Text;
  line2Text!: Phaser.GameObjects.Text;
  lives!: Phaser.Physics.Arcade.Group;
  stateText!: Phaser.GameObjects.Text; // Add this line
  private returnButton?: Button;
  private gameOverTexts: Phaser.GameObjects.Text[] = [];


  get noMoreLives() {
    return this.lives.countActive(true) === 0;
  }

  highScore = 0;
  score = 0;

  constructor(private _scene: Phaser.Scene, private _energy: number) {
    this._init();
    this.print();
    this.initStateText(); // Add this line
  }

  private _init() {
    const { width: SIZE_X, height: SIZE_Y } = this._scene.game.canvas;
    const textConfig = {
      fontFamily: `'Arial', sans-serif`,
      fill: "#ffffff",
    };
    const normalTextConfig = {
      ...textConfig,
      fontSize: "16px",
    };

    const bigTextConfig = {
      ...textConfig,
      fontSize: "36px",
    };

    this._scene.add.text(16, 16, `SCORE`, normalTextConfig);
    this.scoreText = this._scene.add.text(22, 32, "", normalTextConfig);
    this.line1Text = this._scene.add
      .text(SIZE_X / 2, 320, "", bigTextConfig)
      .setOrigin(0.5);

    this.line2Text = this._scene.add
      .text(SIZE_X / 2, 400, "", bigTextConfig)
      .setOrigin(0.5);

    this._setLivesText(SIZE_X, normalTextConfig);
  }

  private _setLivesText(
    SIZE_X: number,
    textConfig: { fontSize: string; fontFamily: string; fill: string }
  ) {
    this._scene.add.text(SIZE_X - 100, 16, `LIVES`, textConfig);
    this.lives = this._scene.physics.add.group({
      maxSize: 3,
      runChildUpdate: true,
    });
    this.resetLives();
  }

  resetLives() {
    let SIZE_X = this._scene.game.canvas.width;
    this.lives.clear(true, true);
    for (let i = 0; i < 3; i++) {
      let ship: Phaser.GameObjects.Sprite = this.lives.create(
        SIZE_X - 100 + 30 * i,
        60,
        AssetType.Ship
      );
      ship.setOrigin(0.5, 0.5);
      ship.setAngle(90);
      ship.setAlpha(0.6);
      ship.setScale(0.5); // Add this line to reduce the sprite size by half
    }
  }

  setWinText() {
    const fontSize = this.getFontSize(32);
    this.stateText.setFontSize(fontSize);
    this.stateText.setText("You won!\nTouch screen to continue");
    this.stateText.visible = true;
  }

  setGameOverText(energy: number) {
    const { width: SIZE_X, height: SIZE_Y } = this._scene.game.canvas;
    const textConfig = {
      fontFamily: `'Arial', sans-serif`,
      fontSize: "32px",
      fill: "#ffffff",
    };

    this.gameOverTexts = [
      this._scene.add
        .text(SIZE_X / 2, SIZE_Y / 2 - 100, "GAME OVER", textConfig)
        .setOrigin(0.5),

      this._scene.add
        .text(
          SIZE_X / 2,
          SIZE_Y / 2,
          `Total Energy Left: ${energy}`,
          textConfig
        )
        .setOrigin(0.5),

      this._scene.add
        .text(SIZE_X / 2, SIZE_Y / 2 + 50, "Touch to continue", textConfig)
        .setOrigin(0.5),
    ];

    // Main Menu button
    const buttonStyle = {
      font: "24px Arial",
      fill: "#ffffff",
      backgroundColor: "#f8ad06",
      padding: {
        x: 20,
        y: 10,
      },
    };

    this.returnButton = createButton(
      this._scene,
      SIZE_X / 2,
      SIZE_Y / 2 + 150,
      "Return to Main Menu",
      buttonStyle
    ).setOrigin(0.5);

    this.returnButton.OnClick(() => {
      (this._scene as MainScene).returnToMainMenu();
    });

    this.gameOverTexts.push(this.returnButton);
  }

  hideText() {
    this.stateText.visible = false;
  }

  private _setBigText(line1: string, line2: string) {
    this.line1Text.setText(line1);
    this.line2Text.setText(line2);
  }

  setHighScore() {
    if (this.score > this.highScore) {
      this.highScore = this.score;
    }
    this.score = 0;
    this.print();
  }

  print() {
    this.scoreText.setText(`${this.padding(this.score)}`);
  }

  increaseScore(step = 10) {
    this.score += step;
    this.print();
  }

  padding(num: number) {
    return `${num}`.padStart(4, "0");
  }

  setNextLevelText(level: number) {
    const fontSize = this.getFontSize(32);
    this.stateText.setFontSize(fontSize);
    this.stateText.setText(
      `Level ${level - 1} complete!\nTouch screen to start level ${level}`
    );
    this.stateText.visible = true;
  }

  private initStateText() {
    const { width: SIZE_X, height: SIZE_Y } = this._scene.scale;
    this.stateText = this._scene.add.text(SIZE_X / 2, SIZE_Y / 2, "", {
      font: this.getFontSize(32) + " Arial",
      color: "#fff",
    });
    this.stateText.setOrigin(0.5);
    this.stateText.setAlign("center");
    this.stateText.visible = false;
  }

  private getFontSize(baseSize: number): string {
    const { width } = this._scene.scale;
    return Math.max(Math.min(width / 15, baseSize), 16) + "px";
  }

  gameOver() {
    console.log("Game over called in ScoreManager");
    // Existing game over logic...
  }

  removeGameOverText() {
    this.gameOverTexts.forEach((text) => text.destroy());

    this.gameOverTexts = [];

    this.returnButton = undefined;

  
  }

  resetScore() {
    this.score = 0;
    this.print();
  }
}
