export enum AssetType {
    Bullet = "bullet",
    EnemyBullet = "enemyBullet",
    Alien = "alien",
    Ship = "ship",
    ShipBlue = "shipBlue",
    ShipGreen = "shipGreen",
    ShipOrange = "shipOrange",
    ShipPurple = "shipPurple",
    Kaboom = "kaboom",
    RedAlien = "redAlien",
    Starfield = "starfield",
    Powerup = "powerup",
    Shield = "shield",
}

export enum SoundType {
    Kaboom = "kaboom",
    Shoot = "shoot",
    InvaderKilled = "invaderKilled",
    Hit = "hit",
    Powerup = "powerup"
}