import { AssetType, SoundType } from "./assets";
import { AnimationType } from "./factory/animation-factory";
import { Kaboom } from "./kaboom";

export class RedAlien extends Phaser.Physics.Arcade.Sprite {
  private health: number = 2;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, AssetType.RedAlien);
    scene.add.existing(this);
    scene.physics.add.existing(this);
    this.setOrigin(0.5);
  }

  hit(explosion: Kaboom): boolean {
    this.health--;
    if (this.health > 0) {
      // Play hit sound
      this.scene.sound.play(SoundType.Hit);
      // Add visual feedback (e.g., tint the sprite red briefly)
      this.setTint(0xff0000);
      this.scene.time.delayedCall(100, () => {
        this.clearTint();
      });
      
      // Reset the velocity to prevent the alien from flying up
      this.setVelocity(0, 0);
      
      return false; // Not killed yet
    } else {
      this.kill(explosion);
      return true; // Killed
    }
  }

  kill(explosion: Kaboom) {
    if (explosion) {
      explosion.setPosition(this.x, this.y);
      explosion.play(AnimationType.Kaboom);
      this.scene.sound.play(SoundType.InvaderKilled);
    }
    this.destroy();
  }
}