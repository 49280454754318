import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import { TelegramProvider } from "./contexts/TelegramContext";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl="https://chickenblaster.xyz/tonconnect-manifest.json">
      <TelegramProvider>
        <App />
      </TelegramProvider>
    </TonConnectUIProvider>
  </React.StrictMode>
);
