import { useState, useEffect } from 'react';


const imageUrls = [
    "https://cdn.chickenblaster.xyz/assets/images/ui/loadscreen/loadscreen_logo.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/menu_bg.jpg",
    "https://cdn.chickenblaster.xyz/assets/images/chicken-red.webp",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/menu_chicken.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/home.svg",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/Store.svg",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/List.svg",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/achievement.svg",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/player.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/Lock.svg",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/player-blue.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/player-green.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/player-orange.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/store/player-purple.png",
    "https://cdn.chickenblaster.xyz/assets/images/ui/menu/gift.svg"
]

const useImagePreloader = () => {
    const [imagesPreloaded, setImagesPreloaded] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const preloadImages = async () => {
            const promises = imageUrls.map((src) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });

            try {
                await Promise.all(promises);
                if (isMounted) {
                    setImagesPreloaded(true);
                }
            } catch (error) {
                console.error('Error preloading images:', error);
            }
        };

        preloadImages();

        return () => {
            isMounted = false;
        };
    }, [imageUrls]);

    return imagesPreloaded;
};

export default useImagePreloader;