import { AssetType } from "./assets";

export class Ship extends Phaser.Physics.Arcade.Sprite {
    private shieldActive: boolean = false;
    private shieldTimer: Phaser.Time.TimerEvent | null = null;
    private shieldSprite: Phaser.GameObjects.Sprite | null = null;
    private static SHIELD_DURATION: number = 5000; // 5 seconds in milliseconds

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string | Phaser.Textures.Texture) {
        super(scene, x, y, texture);
        scene.add.existing(this);
        scene.physics.add.existing(this);
    }

    activateShield() {
        this.shieldActive = true;
        this.updateShieldVisual();

        // Clear existing timer if there's one
        if (this.shieldTimer) {
            this.shieldTimer.remove();
        }

        // Set new timer
        this.shieldTimer = this.scene.time.delayedCall(Ship.SHIELD_DURATION, () => {
            this.deactivateShield();
        });
    }

    hit(): boolean {
        if (this.shieldActive) {
            return false; // Player didn't lose a life
        }
        return true; // Player lost a life
    }

    private deactivateShield() {
        this.shieldActive = false;
        this.updateShieldVisual();
        if (this.shieldTimer) {
            this.shieldTimer.remove();
            this.shieldTimer = null;
        }
    }

    private updateShieldVisual() {
        if (this.shieldActive) {
            if (!this.shieldSprite) {
                this.shieldSprite = this.scene.add.sprite(this.x, this.y, AssetType.Shield);
                this.shieldSprite.setScale(this.scale);
            }
        } else if (this.shieldSprite) {
            this.shieldSprite.destroy();
            this.shieldSprite = null;
        }
    }

    update() {
        if (this.shieldSprite) {
            this.shieldSprite.setPosition(this.x, this.y);
        }
    }
}
