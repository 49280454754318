import React, { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import { MainScene } from '../game/scenes/main';
import { useTelegram } from '../contexts/TelegramContext';
import MainMenu from './mainmenu/MainMenu';
import { globalEventEmitter } from '../utils/eventEmitter';
import ReferralPage from './referral/ReferralPage';
import { Blastd } from '../blastd/blastd';
import { PreloadScene } from '../game/scenes/preload';

const GameComponent: React.FC = () => {
	const gameRef = useRef<HTMLDivElement>(null);
	const gameInstanceRef = useRef<Phaser.Game | null>(null);
	const { webApp } = useTelegram();
	const [showMenu, setShowMenu] = useState(true);
	const [showReferral, setShowReferral] = useState(false);
	const [energy, setEnergy] = useState(0);
	const [activeShip, setActiveShip] = useState(0);

	useEffect(() => {
		if (gameRef.current && webApp && !gameInstanceRef.current && !showMenu) {
			const config: Phaser.Types.Core.GameConfig = {
				type: Phaser.AUTO,
				parent: gameRef.current,
				scene: [PreloadScene, MainScene],
				physics: {
					default: "arcade",
					arcade: {
						debug: false
					}
				},
				callbacks: {
					preBoot: (game) => {
						game.registry.set('webApp', webApp);
						game.registry.set('energy', energy);
						game.registry.set('activeShip', activeShip);
					}
				},
				scale: {
					mode: Phaser.Scale.RESIZE,
					width: '100%',
					height: '100%',
					min: {
						width: 300,
						height: 400
					},
					max: {
						width: 600,
						height: 800
					}
				}
			};

			gameInstanceRef.current = new Phaser.Game(config);

			webApp.expand();

			const resizeGame = () => {
				if (gameInstanceRef.current) {
					const width = Math.min(window.innerWidth, 600);
					const height = window.innerHeight;
					gameInstanceRef.current.scale.resize(width, height);
				}
			};

			window.addEventListener('resize', resizeGame);

			// Add event listener for returning to main menu
			gameInstanceRef.current.events.on('returnToMainMenu', () => {
				if (gameInstanceRef.current) {
					gameInstanceRef.current.destroy(true);
					gameInstanceRef.current = null;
				}
				setShowMenu(true);
			});

			return () => {
				window.removeEventListener('resize', resizeGame);
				if (gameInstanceRef.current) {
					gameInstanceRef.current.destroy(true);
					gameInstanceRef.current = null;
				}
			};
		}
	}, [webApp, showMenu]);

	useEffect(() => {
		console.log('Setting up gameOver event listener');
		const handleGameOver = async (score: number) => {
			console.log(`Game over event received in GameComponent. Score: ${score}`);
			try {
				console.log('Calling submitScore from GameComponent');
				await submitScore(score);
				console.log('Score submitted successfully');
			} catch (error) {
				console.error('Error in submitScore:', error);
			}
		};

		globalEventEmitter.on('gameOver', handleGameOver);

		return () => {
			globalEventEmitter.off('gameOver', handleGameOver);
			console.log('Removed gameOver event listener');
		};
	}, []);

	const submitScore = async (score: number) => {
		try {
			await Blastd.getInstance().submitScore(score);
			console.log('Score submitted successfully');
		} catch (error) {
			console.error('Error submitting score:', error);
			throw error;
		}
	};

	const handlePlayClick = (energy: number, activeShip: number) => {
		setActiveShip(activeShip);
		setEnergy(energy);
		setShowMenu(false);
	};

	const handleTasksClick = () => {
		console.log('Tasks button clicked');
		// For now, this function doesn't do anything else
	};

	const handleAchievementClick = () => {
		// Implement achievement functionality here
		console.log('Achievement clicked');
	};

	return (
		<div ref={gameRef} id="game-container">
			{showMenu ? (
				<MainMenu
					onPlayClick={handlePlayClick}
					onTasksClick={handleTasksClick}
					onAchievementClick={handleAchievementClick}
				/>
			) : null}
			{showReferral && (
				<ReferralPage onClose={() => setShowReferral(false)} />
			)}
		</div>
	);
};

export default GameComponent;