export class LevelManager {
    private currentLevel: number = 1;
    private maxAliensPerRow: number = 10;
    private maxRows: number = 5;
    private alienSpeed: number = 50;
    private alienFireRate: number = 1000;

    constructor() {
        this.reset();
    }

    reset() {
        this.currentLevel = 1;
        this.maxAliensPerRow = 5;
        this.maxRows = 5;
        this.resetLevelParameters();
    }

    resetLevelParameters() {
        this.alienSpeed = 2000;
        this.alienFireRate = 2000;
    }

    nextLevel() {
        this.currentLevel++;
        this.alienSpeed = Math.max(this.alienSpeed - 100, 1000);
        this.alienFireRate = Math.max(this.alienFireRate - 100, 1000);
    }

    getLevelConfig() {
        const baseAliens = Math.min(this.currentLevel + 2, 20);
        const totalAliens = Phaser.Math.Clamp(baseAliens + Phaser.Math.Between(-1, 1), 3, 25);
        const rows = Math.ceil(totalAliens / this.maxAliensPerRow);
        const aliensPerRow = this.distributeAliens(totalAliens, rows);

        return {
            level: this.currentLevel,
            aliensPerRow: aliensPerRow,
            rows: rows,
            alienSpeed: this.alienSpeed,
            alienFireRate: this.alienFireRate
        };
    }

    private distributeAliens(totalAliens: number, rows: number): number[] {
        let aliensPerRow = [];
        let remainingAliens = totalAliens;

        for (let i = 0; i < rows && remainingAliens > 0; i++) {
            const aliensInThisRow = Math.min(remainingAliens, this.maxAliensPerRow);
            aliensPerRow.push(aliensInThisRow);
            remainingAliens -= aliensInThisRow;
        }

        return aliensPerRow;
    }
}
