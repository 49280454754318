import React, { useState, useEffect } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';
import { generateReferralCode, createReferralLink, storeReferralData, getReferralData } from './referralUtils';
import './ReferralPage.css';

interface ReferralPageProps {
  onClose: () => void;
}

const ReferralPage: React.FC<ReferralPageProps> = ({ onClose }) => {
  const [referralLink, setReferralLink] = useState<string>('');
  const [totalReferrals, setTotalReferrals] = useState<number>(0);
  const walletAddress = useTonAddress();

  useEffect(() => {
    const fetchReferralData = async () => {
      if (walletAddress) {
        const data = await getReferralData(walletAddress);
        if (data) {
          setReferralLink(createReferralLink(data.referral_code));
          setTotalReferrals(data.total_referrals || 0);
        } else {
          const newReferralCode = generateReferralCode();
          await storeReferralData(walletAddress, newReferralCode);
          setReferralLink(createReferralLink(newReferralCode));
        }
      }
    };

    fetchReferralData();
  }, [walletAddress]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  return (
    <div className="referral-page">
      <h2>Refer Friends</h2>
      <p>Share your referral link with friends:</p>
      <div className="referral-link">
        <input type="text" value={referralLink} readOnly />
        <button onClick={copyToClipboard}>Copy</button>
      </div>
      <p>Total Referrals: {totalReferrals}</p>
      <button className="close-button" onClick={onClose}>Close</button>
    </div>
  );
};

export default ReferralPage;
