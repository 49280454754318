import React, { useState, useEffect, useRef } from "react";
import "./css/App.css";
import GameComponent from "./components/GameHome";
import WalletConnection, { ViewState } from "./components/walletconnect/WalletConnection";
import LoadingScreen from "./components/loadingscreen/LoadingScreen";
import { Blastd } from "./blastd/blastd";
import { useTonConnectUI, Wallet } from "@tonconnect/ui-react";
import useImagePreloader from "./utils/imgpreloader";

declare global {
	interface Window {
		Telegram: any;
	}
}

function App() {
	const blastd = Blastd.getInstance()
	const [viewState, setViewState] = useState<ViewState>("connect");
	const [isLoading, setIsLoading] = useState(true);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [paymentVerified, setPaymentVerified] = useState(false)
	const [tonConnectUI] = useTonConnectUI();
	const callbackRegistered = useRef(false);
	const imagesPreloaded = useImagePreloader()
	const isAuthenticating = useRef(false)

	const tg = window.Telegram.WebApp;

	if (tg && tg.initDataUnsafe.user) {
		const user = tg.initDataUnsafe.user;
		console.log('Username:', user.username);
		console.log('Telegram ID:', user.id);
	} else {
		console.log('User information not available');
	}

	const refreshPayload = async () => {
		tonConnectUI.setConnectRequestParameters({ state: "loading" });
		const payloadHex = await blastd.getPayload()
		if (!payloadHex) {
			tonConnectUI.setConnectRequestParameters(null);
		} else {
			tonConnectUI.setConnectRequestParameters({ state: "ready", value: { tonProof: payloadHex } });
		}
	}

	const tryAuthenticate = async (w: Wallet) => {
		if (isAuthenticating.current) {
			return
		}
		isAuthenticating.current = true
		console.log("tryAuthenticate")
		setIsLoading(true)
		setLoadingProgress(15)

		const authenticated = await blastd.verifySession()
		if (!authenticated) {
			await tonConnectUI.disconnect()

			await refreshPayload()
			setViewState("connect")
			setIsLoading(false)
			isAuthenticating.current = false
			return
		}

		setLoadingProgress(60)

		const paid = await blastd.getUserPaidStatus(w.account.address)
		if (!paid) {
			setViewState("pay")
			setIsLoading(false)
			isAuthenticating.current = false
			return
		}

		setPaymentVerified(true)
		setLoadingProgress(100)

		setTimeout(() => {
			setIsLoading(false)
			isAuthenticating.current = false
		}, 1000)
	}

	useEffect(() => {

		console.log("imagesPreloaded", imagesPreloaded)
		if (!imagesPreloaded) {
			return
		}

		console.log("starting timeout", tonConnectUI.connected)
		const cancel = setTimeout(() => {

			console.log("App useEffect tonConnectUI", tonConnectUI.connected)

			// status change wont trigger if wallet is already connected
			if (tonConnectUI.connected) {
				tryAuthenticate(tonConnectUI.wallet!!)
			} else {
				refreshPayload().then(() => {
					setIsLoading(false)
				})
			}

		}, 1500)

		if (!callbackRegistered.current) {
			callbackRegistered.current = true;
			tonConnectUI.onStatusChange(async (w) => {
				if (!w) {
					console.log("Status change: wallet disconnected");
					return;
				} else {
					console.log("Status change: wallet connected");

					if (w.connectItems?.tonProof && "proof" in w.connectItems.tonProof) {
						setIsLoading(true)
						setLoadingProgress(0)

						var proof = w.connectItems.tonProof.proof as any
						proof.state_init = w.account.walletStateInit

						const verified = await blastd.verifyProof(w.account.address, proof)
						if (!verified) {
							console.log("Proof verification failed")
							tonConnectUI.disconnect()
							return
						}
					}

					// check if user is authenticated
					tryAuthenticate(w)
				}
			})
		}

		return () => {
			console.log("clearing timeout")
			clearTimeout(cancel)
		}
	}, [imagesPreloaded]);

	// if(!tg.initDataUnsafe.user) {
	// 	return <div className="miniapponly">Telegram Mini App Only!</div>
	// }

	if (isLoading) {
		return <LoadingScreen progress={loadingProgress} />;
	}

	return (
		<div className="App">
			{!paymentVerified ? (
				<WalletConnection viewState={viewState} tryAuth={tryAuthenticate} />
			) : (
				<GameComponent />
			)}
		</div>
	);
}

export default App;
