import React, { useState, useEffect } from "react";
import "./TaskScreen.css";
import { Blastd } from "../../blastd/blastd";

interface Task {
	id: number;
	title: string;
	reward: number;
	completed: boolean;
	link: string;
}

const initialTasks: Task[] = [
	{ id: 1, title: "Follow @ChickenBlastof on Twitter", reward: 100, completed: false, link: "https://x.com/chickenblastof?s=21" },
	{ id: 2, title: "Subscribe to The Chicken blast off announcement channel", reward: 200, completed: false, link: "https://t.me/chickenblastoff" },
	{ id: 3, title: "Join the chicken blast off TG Group Chat", reward: 500, completed: false, link: "https://t.me/+NHznc9mPMOk0ZjNk" },
	{ id: 4, title: "Like @ChickenBlastof post", reward: 150, completed: false, link: "https://x.com/chickenblastof/status/1846634561033126146?s=46&t=2-2olOK1bY3XvI5R9_Txww" },
	{ id: 5, title: "Retweet @ChickenBlastof post", reward: 50, completed: false, link: "https://x.com/chickenblastof/status/1846634561033126146?s=46&t=2-2olOK1bY3XvI5R9_Txww" },
];

const TaskScreen: React.FC = () => {
	const blastd = Blastd.getInstance()
	const [tasks, setTasks] = useState<Task[]>(initialTasks);

	useEffect(() => {
		fetchTaskCompletionStatus();
	}, []);

	const fetchTaskCompletionStatus = async () => {
		try {
			const completedTasks = await blastd.getCompletedTasks();
			let newTasks = [...tasks];
			for (let i = 0; i < completedTasks.length; i++) {

				newTasks.map(task => {
					if (completedTasks[i] === task.id) {
						task.completed = true;
					}
					return task;
				})
			}
			setTasks(newTasks)
		} catch (error) {
			console.error("Error fetching task completion status:", error);
		}
	};

	const completeTasks = async (id: number) => {
		try {
			const taskToUpdate = tasks.find(task => task.id === id);
			if (!taskToUpdate || taskToUpdate.completed) return;

			// Open the link in a new tab
			window.open(taskToUpdate.link, '_blank');

			await blastd.setTaskCompleted(id);
			tasks[tasks.indexOf(taskToUpdate)].completed = true;
			setTasks([...tasks]);
		} catch (error) {
			console.error("Error updating task status:", error);
		}
	};

	return (
		<>
			<div className="task-header-container">
				<h1 className="task-header">Tasks</h1>
				<p className="task-description">
					Complete tasks to earn more points
				</p>
			</div>

			<div className="task-screen">
				<div className="task-list">
					{tasks.map((task) => (
						<div
							key={task.id}
							className={`task-item ${!task.completed ? "completed" : ""}`}
							onClick={() => completeTasks(task.id)}
						>
							<div className="task-info">
								<img src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/gift.svg" className="task-icon" alt="task icon" />
								<div className="task-content">
									<div className="task-title">{task.title}</div>
									<div className="task-reward">
										+{task.reward.toLocaleString()} Points
									</div>
								</div>
							</div>
							<div className="task-check">
								<div className="checkbox-wrapper-19">
									<input type="checkbox" id="cbtest-19" checked={task.completed} disabled />
									<label htmlFor="cbtest-19" className="check-box" />
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default TaskScreen;