import React from 'react';
import './LoadingScreen.css';

interface LoadingScreenProps {
  progress: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ progress }) => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <img src="https://cdn.chickenblaster.xyz/assets/images/ui/loadscreen/loadscreen_logo.png" alt="Chicken" className="chicken-logo" />
        <p className="loading-text">A classic arcade-style game where players defend against waves of invading chickens.</p>
        <div className="loading-bar-container">
          <div className="loading-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;