import CryptoJS from 'crypto-js';
import { supabase } from '../../utils/supabase';

export const generateReferralCode = (): string => {
  // Generate a random 3-byte (24-bit) value and convert it to a 6-character hex string
  return CryptoJS.lib.WordArray.random(3).toString().slice(0, 6);
};

export const createReferralLink = (referralCode: string): string => {
  return `${window.location.origin}?ref=${referralCode}`;
};

export const storeReferralData = async (walletAddress: string, referralCode: string) => {
  const { data, error } = await supabase
    .from('referrals')
    .upsert(
      { wallet_address: walletAddress, referral_code: referralCode },
      { onConflict: 'wallet_address' }
    );

  if (error) {
    console.error('Error storing referral data:', error);
    return null;
  }
  return data;
};

export const getReferralData = async (walletAddress: string) => {
  const { data, error } = await supabase
    .from('referrals')
    .select('referral_code, total_referrals')
    .eq('wallet_address', walletAddress)
    .single();

  if (error) {
    console.error('Error fetching referral data:', error);
    return null;
  }
  return data;
};
