import { Alien } from "../alien";
import { RedAlien } from "../red-alien";
import { AnimationType } from "../factory/animation-factory";
import { Powerup } from "../powerup";

export class AlienManager {
    aliens: Phaser.Physics.Arcade.Group;
    powerups: Phaser.Physics.Arcade.Group;
    private aliensKilledSinceLastPowerup: number = 0;
    private aliensToKillForPowerup: number = 0;

    get hasAliveAliens(): boolean {
        return this.aliens.countActive() > 0;
    }

    constructor(private _scene: Phaser.Scene, private _levelConfig: any) {
        this.aliens = this._scene.physics.add.group({
            classType: Alien,
            runChildUpdate: true
        });
        this.powerups = this._scene.physics.add.group({
            classType: Powerup,
            runChildUpdate: true
        });
        this._sortAliens();
        this._animate();
        this.resetPowerupCounter();
    }

    getRandomAliveEnemy(): Alien | RedAlien {
        const aliens = this.aliens.getChildren().filter(alien => alien.active) as (Alien | RedAlien)[];
        return Phaser.Utils.Array.GetRandom(aliens);
    }

    reset(levelConfig: any) {
        this._levelConfig = levelConfig;
        this.aliens.clear(true, true);
        this._sortAliens();
        this._animate();
        this.resetPowerupCounter();
    }

    private _sortAliens() {
        const gameWidth = this._scene.scale.width;
        const gameHeight = this._scene.scale.height;
        const isMobile = gameWidth < 600;
    
        const alienWidth = isMobile ? 60 : 80;
        const alienHeight = isMobile ? 37.5 : 50;
        const padding = isMobile ? 5 : 10;
        const scale = isMobile ? 0.09 : 0.12;

        const startY = gameHeight / 4;

        this.aliens.clear(true, true);

        this._levelConfig.aliensPerRow.forEach((aliensInRow: number, rowIndex: number) => {
            const rowWidth = aliensInRow * (alienWidth + padding) - padding;
            const startX = (gameWidth - rowWidth) / 2;

            for (let x = 0; x < aliensInRow; x++) {
                const xPosition = startX + x * (alienWidth + padding);
                const yPosition = startY + rowIndex * (alienHeight + padding);

                let alien: Alien | RedAlien;
                if (this._levelConfig.level >= 5) {
                    alien = new RedAlien(this._scene, xPosition, yPosition);
                } else {
                    alien = new Alien(this._scene, xPosition, yPosition);
                }

                this.aliens.add(alien);
                alien.setScale(scale);

                console.log(`Created ${this._levelConfig.level >= 5 ? 'RedAlien' : 'Alien'} at (${xPosition}, ${yPosition})`);
            }
        });

        console.log(`Total aliens created: ${this.aliens.getLength()}`);
    }

    private _animate() {
        this.aliens.getChildren().forEach((alien: Phaser.GameObjects.GameObject) => {
            const typedAlien = alien as Alien | RedAlien;
            this._scene.tweens.add({
                targets: typedAlien,
                ease: "Linear",
                duration: this._levelConfig.alienSpeed,
                x: "+=100",
                paused: false,
                delay: 0,
                yoyo: true,
                repeat: -1
            });
        });
    }

    drawDebugRectangles(graphics: Phaser.GameObjects.Graphics) {
        graphics.lineStyle(2, 0x00ff00);
        this.aliens.getChildren().forEach((alien: Phaser.GameObjects.GameObject) => {
            const typedAlien = alien as Alien | RedAlien;
            const bounds = typedAlien.getBounds();
            graphics.strokeRect(bounds.x, bounds.y, bounds.width, bounds.height);
        });
    }

    private resetPowerupCounter() {
        this.aliensKilledSinceLastPowerup = 0;
        this.aliensToKillForPowerup = Phaser.Math.Between(5, 10);
    }

    public alienKilled(alien: Alien | RedAlien): boolean {
        this.aliensKilledSinceLastPowerup++;
        if (this.aliensKilledSinceLastPowerup >= this.aliensToKillForPowerup) {
            this.spawnPowerup(alien.x, alien.y);
            this.resetPowerupCounter();
            return true;
        }
        return false;
    }

    private spawnPowerup(x: number, y: number) {
        const powerup = new Powerup(this._scene, x, y);
        this.powerups.add(powerup);
        powerup.setVelocityY(100); // Set velocity again after adding to group
        console.log(`Powerup added to group at (${x}, ${y})`);
    }
}