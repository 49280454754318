import React from "react";
import "./Story.css";
const Story: React.FC = () => {
  return (
    <div className="lore">
      <div className="intro">The chickens have had enough!</div>
      <div className="board">
        <div className="content">
          <p>
            Once harmless farm birds, they’ve now turned into an army of winged
            invaders, determined to take over the world.
            <br />
            <br />
            As the chaos unfolds, it’s up to you to defend against their
            relentless attack.
            <br />
            <br />
            Armed with powerful weapons and quick reflexes, you must battle wave
            after wave of furious chickens, each more cunning than the last.
            <br />
            <br />
            Can you stop the feathered takeover before it’s too late?
            <br />
            <br />
            The farm, and the world, are counting on you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Story;
