import { AssetType, SoundType } from "./assets";
import { AnimationType } from "./factory/animation-factory";
import { Kaboom } from "./kaboom";

export class Alien extends Phaser.Physics.Arcade.Sprite {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, AssetType.Alien);
    scene.add.existing(this);
    scene.physics.add.existing(this);
    this.setOrigin(0.5);
  }

  kill(explosion: Kaboom) {
    if (explosion) {
      explosion.setPosition(this.x, this.y);
      explosion.play(AnimationType.Kaboom);
      this.scene.sound.play(SoundType.InvaderKilled);
    }
    this.destroy();
  }
}