import React, { useState, useEffect } from "react";
import { useTonAddress } from "@tonconnect/ui-react";
import "./MainMenu.css";
import "../store/Store.css";
import TaskScreen from "../taskscreen/TaskScreen";
import Achievement from "../achievement/achievement";
import Store from "../store/Store";
import { Blastd } from "../../blastd/blastd";
import { Utils } from "../../utils/utils";

interface MainMenuProps {
	onPlayClick: (energy: number, activeShip: number) => void;
	onTasksClick: () => void;
	onAchievementClick: () => void;
}

interface StoreItem {
	name: string;
	sprite: string;
	price: number;
	description?: string;
}

const shipOptions: StoreItem[] = [
	{
		name: "Default",
		sprite: "player.png",
		price: 0,
		description: "Lorem ipsum",
	},
	{
		name: "Blue",
		sprite: "player-blue.png",
		price: 0.1,
		description: "Lorem ipsum",
	},
	{
		name: "Green",
		sprite: "player-green.png",
		price: 0.1,
		description: "Lorem ipsum",
	},
	{
		name: "Orange",
		sprite: "player-orange.png",
		price: 0.1,
		description: "Lorem ipsum",
	},
	{
		name: "Purple",
		sprite: "player-purple.png",
		price: 0.1,
		description: "Lorem ipsum",
	},
];

const livesOptions: StoreItem[] = [
	{
		name: "Extra Life",
		sprite: "extra-life.png",
		price: 0.1,
		description: "Lorem ipsum",
	},
	// Add more lives options if needed
];

// Update the EnergyData interface
interface EnergyData {
	CurrentEnergy: number;
	NextRefillSeconds: number;
}

const MainMenu: React.FC<MainMenuProps> = ({
	onPlayClick,
	onTasksClick,
	onAchievementClick,
}) => {
	const [totalPoints, setTotalPoints] = useState(0);
	const [energy, setEnergy] = useState<EnergyData | null>(null);
	const [activePage, setActivePage] = useState("home");
	const [activeStoreTab, setActiveStoreTab] = useState("Ships");
	const userFriendlyAddress = useTonAddress();

	const blastd = Blastd.getInstance();

	const [energyRefillCountdown, setEnergyRefillCountdown] = useState<
		number | null
	>(null);

	useEffect(() => {
		const fetchTotalPoints = async () => {
			try {
				const points = await blastd.fetchTotalPoints();
				setTotalPoints(points);
			} catch (error) {
				console.error("Error fetching total points:", error);
			}
		}
		fetchTotalPoints()
		fetchEnergy();

		const pointsInterval = setInterval(fetchTotalPoints, 60 * 1000);
		const energyInterval = setInterval(fetchEnergy, 60 * 1000);

		return () => {
			clearInterval(pointsInterval);
			clearInterval(energyInterval);
		};
	}, []);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;

		if (energy && energy.CurrentEnergy < 4 && energy.NextRefillSeconds > 0) {
			setEnergyRefillCountdown(energy.NextRefillSeconds);

			intervalId = setInterval(() => {
				setEnergyRefillCountdown((prevCountdown) => {
					if (prevCountdown !== null && prevCountdown > 0) {
						return prevCountdown - 1;
					} else {
						clearInterval(intervalId);
						fetchEnergy(); // Refresh energy data when countdown reaches 0
						return null;
					}
				});
			}, 1000);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [energy]);

	const fetchEnergy = async () => {
		try {
			const energyData = await blastd.fetchEnergy();
			setEnergy(energyData as EnergyData);
		} catch (error) {
			console.error("Error fetching energy:", error);
		}
	};

	const handlePlayClick = async () => {
		try {
			const startReq = await blastd.startGame();
			console.log("Game started successfully");
			onPlayClick(startReq.energy, startReq.ship);
		}catch(error) {
			console.log("Not enough lives to start the game");
		}
	};

	const handleMenuItemClick = (page: string) => {
		setActivePage(page);
		if (page === "tasks") {
			onTasksClick();
		} else if (page === "achievement") {
			onAchievementClick();
		}
	};

	const renderContent = () => {
		switch (activePage) {
			case "home":
				return (
					<div className="play-section">
						<div className="energy-section">
							Total energy:{" "}
							{energy !== null ? energy.CurrentEnergy : "Loading..."}
							{energy &&
								energy.CurrentEnergy < 4 &&
								energyRefillCountdown !== null && (
									<p>
										Time left to energy refill:{" "}
										{Utils.formatTime(energyRefillCountdown)}
									</p>
								)}
						</div>
						<img
							src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/menu_chicken.png"
							alt="Chicken"
						/>
						<button className="play-button" onClick={handlePlayClick}>
							Play game
						</button>
					</div>
				);
			case "store":
				return (
					<Store
						activeStoreTab={activeStoreTab}
					// shipOptions={shipOptions}
					// livesOptions={livesOptions}
					/>
				);
			case "tasks":
				return <TaskScreen />;
			case "achievement":
				return <Achievement />;
			default:
				return null;
		}
	};

	return (
		<div className="main-menu">
			<div className="profile-section">
				<div className="profile-info">
					<img
						src="https://cdn.chickenblaster.xyz/assets/images/chicken-red.webp"
						alt="Profile"
						className="profile-image"
					/>
					{/* <h2>{Utils.truncateAddress(userFriendlyAddress || "")}</h2> */}
					<h2>{ userFriendlyAddress.substring(userFriendlyAddress.length - 6) }</h2>
				</div>
				<p>{totalPoints} points</p>
			</div>
			{renderContent()}

			{/* Tabs */}
			<div className="menu-items">
				<button
					onClick={() => handleMenuItemClick("home")}
					className={activePage === "home" ? "active" : ""}
				>
					<img
						src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/home.svg"
						alt="Home"
					/>
					<span>Home</span>
				</button>
				<button
					onClick={() => handleMenuItemClick("store")}
					className={activePage === "store" ? "active" : ""}
				>
					<img
						src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/Store.svg"
						alt="Store"
					/>
					<span>Store</span>
				</button>
				<button
					onClick={() => handleMenuItemClick("tasks")}
					className={activePage === "tasks" ? "active" : ""}
				>
					<img
						src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/List.svg"
						alt="Tasks"
					/>
					<span>Tasks</span>
				</button>
				<button
					onClick={() => handleMenuItemClick("achievement")}
					className={activePage === "achievement" ? "active" : ""}
				>
					<img
						src="https://cdn.chickenblaster.xyz/assets/images/ui/menu/achievement.svg"
						alt="Achievement"
					/>
					<span>Achievement</span>
				</button>
			</div>
		</div>
	);
};

export default MainMenu;
