import { AssetType, SoundType } from "../interface/assets";
import { Bullet } from "../interface/bullet";
import { AssetManager } from "../interface/manager/asset-manager";
import { AlienManager } from "../interface/manager/alien-manager";
import { Ship } from "../interface/ship";
import {
  AnimationFactory,
  AnimationType,
} from "../interface/factory/animation-factory";
import { Alien } from "../interface/alien";
import { RedAlien } from "../interface/red-alien"; // Add this line
import { Kaboom } from "../interface/kaboom";
import { EnemyBullet } from "../interface/enemy-bullet";
import { ScoreManager } from "../interface/manager/score-manager";
import { GameState } from "../interface/game-state";
import { LevelManager } from "../interface/manager/LevelManager";
import { EventEmitter } from "events";
import { globalEventEmitter } from "../../utils/eventEmitter";
import { Powerup } from "../interface/powerup";
import { Blastd } from "../../blastd/blastd";

export const gameEvents = new EventEmitter();

export class MainScene extends Phaser.Scene {
  state: GameState;
  assetManager!: AssetManager;
  animationFactory!: AnimationFactory;
  scoreManager!: ScoreManager;
  bulletTime: number;
  firingTimer: number;
  starfield!: Phaser.GameObjects.TileSprite;
  player!: Ship;
  alienManager!: AlienManager;
  cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  fireKey!: Phaser.Input.Keyboard.Key;
  levelManager: LevelManager;
  private isDragging: boolean = false;
  private lastPointerPosition: Phaser.Math.Vector2 | null = null;
  private energy!: number;
  debugGraphics!: Phaser.GameObjects.Graphics;
  private blastd: Blastd;

  constructor() {
    super({ key: "MainScene" });
    this.state = GameState.Playing;
    this.bulletTime = 0;
    this.firingTimer = 0;
    this.levelManager = new LevelManager();
    this.blastd = Blastd.getInstance();
  }

  init(data: { energy: number }) {
    this.energy = data.energy;
  }

  preload() {
  }

  create() {
    this.state = GameState.Playing;
    this.starfield = this.add
      .tileSprite(0, 0, 600, 800, AssetType.Starfield)
      .setOrigin(0, 0);
    this.assetManager = new AssetManager(this);
    this.animationFactory = new AnimationFactory(this);
    this.cursors =
      this.input.keyboard?.createCursorKeys() ??
      ({} as Phaser.Types.Input.Keyboard.CursorKeys);
    this.fireKey =
      this.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE) ??
      ({} as Phaser.Input.Keyboard.Key);
    const gameHeight = this.scale.height;
    const gameWidth = this.scale.width;

    const isMobile = gameWidth < 600;

    const playerY = gameHeight - 70;
    const mobileOffset = isMobile ? 15 : 0;

    const selectedShip = localStorage.getItem("selectedShip") || "player.png";
    const shipAssetType = this.getShipAssetType(selectedShip);

    this.player = new Ship(this, 400, playerY - mobileOffset, shipAssetType);
    this.player.setCollideWorldBounds(true);

    const levelConfig = this.levelManager.getLevelConfig();
    console.log("Level config:", levelConfig);
    this.alienManager = new AlienManager(this, levelConfig);

    this.physics.add.collider(
      this.assetManager.bullets,
      this.alienManager.aliens,
      this._bulletHitAlien as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback,
      undefined,
      this
    );
    const energy = this.registry.get("energy") ?? 4; // Use 4 as default if not set
    this.energy = energy;
    this.scoreManager = new ScoreManager(this, this.energy);

    this.fireKey.on("down", () => {
      this.handleStateTransition();
    });

    // Add this line to hide the text initially
    this.scoreManager.hideText();

    this.input.on("pointerdown", this.onPointerDown, this);
    this.input.on("pointermove", this.onPointerMove, this);
    this.input.on("pointerup", this.onPointerUp, this);
    this.input.on("pointerdown", this.handleContinueTouch, this);
    this.input.keyboard?.on("keydown-SPACE", this.handleContinueKeyboard, this);

    this.events.on("shutdown", () => {
      console.log("Game scene shutting down, score:", this.scoreManager.score);
      gameEvents.emit("gameOver", this.scoreManager.score);
    });

    if (this.physics.world.drawDebug) {
      this.debugGraphics = this.add.graphics();
    }

    this.physics.add.overlap(
      this.player,
      this.alienManager.powerups,
      this
        ._playerHitPowerup as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback,
      undefined,
      this
    );

    // Check if the powerup sound is loaded
    if (this.sound.get(SoundType.Powerup)) {
      console.log("Powerup sound loaded successfully");
    } else {
      console.warn("Powerup sound not loaded");
    }
  }

  update() {
    // Change starfield scrolling direction
    this.starfield.tilePositionY += 1;
    if (!this.input.activePointer.isDown) {
      this._shipKeyboardHandler();
    }
    if (this.time.now > this.firingTimer) {
      this._enemyFires();
    }

    this.physics.overlap(
      this.assetManager.enemyBullets,
      this.player,
      this
        ._enemyBulletHitPlayer as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback,
      undefined,
      this
    );

    // Add bounds checking for enemy bullets and explosions
    this.assetManager.enemyBullets
      .getChildren()
      .forEach((bullet: Phaser.GameObjects.GameObject) => {
        this.enforceGameBounds(bullet as EnemyBullet);
      });
    this.assetManager.explosions
      .getChildren()
      .forEach((explosion: Phaser.GameObjects.GameObject) => {
        this.enforceGameBounds(explosion as Kaboom);
      });

    // Add bounds checking for powerups
    this.alienManager.powerups
      .getChildren()
      .forEach((powerup: Phaser.GameObjects.GameObject) => {
        const typedPowerup = powerup as Powerup;
        typedPowerup.update();
        this.enforceGameBounds(typedPowerup);
      });

    if (this.physics.world.drawDebug) {
      this.debugGraphics.clear();
      this.alienManager.drawDebugRectangles(this.debugGraphics);
    }

    this.player.update();
  }

  private enforceGameBounds(sprite: Phaser.GameObjects.Sprite) {
    const margin = 10; // A small margin to ensure sprites are fully off-screen before removal
    const { width, height } = this.scale;

    if (
      sprite.y < 100 || // Top bound, accounting for UI area
      sprite.y > height + margin ||
      sprite.x < -margin ||
      sprite.x > width + margin
    ) {
      if (sprite instanceof EnemyBullet) {
        sprite.kill();
      } else if (sprite instanceof Kaboom) {
        sprite.destroy();
      } else if (sprite instanceof Powerup) {
        if (sprite.y > this.scale.height + margin) {
          sprite.destroy();
          console.log("Powerup destroyed (out of bounds)");
        }
      }
    }
  }

  private _bulletHitAlien(
    bullet: Phaser.GameObjects.GameObject,
    alien: Phaser.GameObjects.GameObject
  ) {
    const typedBullet = bullet as Bullet;
    const typedAlien = alien as Alien | RedAlien;
    typedBullet.kill();

    if (typedAlien instanceof RedAlien) {
      let explosion: Kaboom = this.assetManager.explosions.get();
      const killed = typedAlien.hit(explosion);
      if (killed) {
        this.scoreManager.increaseScore(20);
        this.alienManager.alienKilled(typedAlien);
      } else {
        this.scoreManager.increaseScore(5);
      }
    } else {
      let explosion: Kaboom = this.assetManager.explosions.get();
      typedAlien.kill(explosion);
      this.scoreManager.increaseScore(10);
      this.alienManager.alienKilled(typedAlien);
    }

    if (!this.alienManager.hasAliveAliens) {
      this.scoreManager.increaseScore(50);
      this.state = GameState.Win;
      this.clearEnemyBullets();
      this.scoreManager.setNextLevelText(
        this.levelManager.getLevelConfig().level + 1
      );
    }
  }

  private _shipKeyboardHandler() {
    let playerBody = this.player.body as Phaser.Physics.Arcade.Body;
    playerBody.setVelocity(0, 0);
    if (this.cursors.left.isDown) {
      playerBody.setVelocityX(-200);
    } else if (this.cursors.right.isDown) {
      playerBody.setVelocityX(200);
    }

    if (this.fireKey.isDown) {
      this._fireBullet();
    }
  }


  private _enemyBulletHitPlayer(
    player: Phaser.GameObjects.GameObject,
    enemyBullet: Phaser.GameObjects.GameObject
  ) {
    const typedPlayer = player as Ship;
    const typedEnemyBullet = enemyBullet as EnemyBullet;
    typedEnemyBullet.kill();

    if (typedPlayer.hit()) {
      let explosion: Kaboom = this.assetManager.explosions.get();
      let live: Phaser.GameObjects.Sprite =
        this.scoreManager.lives.getFirstAlive();
      if (live) {
        live.setActive(false).setVisible(false);
      }

      // Ensure the explosion spawns within the game area
      const y = Math.max(this.player.y, 100);
      explosion.setPosition(this.player.x, y);
      explosion.play(AnimationType.Kaboom);
      this.sound.play(SoundType.Kaboom);

      if (this.scoreManager.noMoreLives) {
        console.log("No more lives, setting game over state");
        
        this.scoreManager.setGameOverText(this.energy);
        this.assetManager.gameOver();
        this.state = GameState.GameOver;
        this.clearEnemyBullets();
        this.player.disableBody(true, true);
        globalEventEmitter.emit("gameOver", this.scoreManager.score);
      }
    }
  }

  private _enemyFires() {
    if (!this.player.active) {
      return;
    }
    let enemyBullet: EnemyBullet = this.assetManager.enemyBullets.get();
    let randomEnemy = this.alienManager.getRandomAliveEnemy();
    if (enemyBullet && randomEnemy) {
      const y = Math.max(randomEnemy.y, 100);
      enemyBullet.setPosition(randomEnemy.x, y);
      this.physics.moveToObject(enemyBullet, this.player, 120);
      
      // Calculate firing interval based on current level
      const baseInterval = 2000;
      const levelReduction = 100 * this.levelManager.getLevelConfig().level;
      const firingInterval = Math.max(baseInterval - levelReduction, 300); // Minimum 500ms interval
      
      this.firingTimer = this.time.now + firingInterval;
    }
  }

  private _fireBullet() {
    if (this.time.now > this.bulletTime) {
      let bullet: Bullet = this.assetManager.bullets.get();
      if (bullet) {
        bullet.shoot(this.player.x, this.player.y - 18);
        this.bulletTime = this.time.now + 200;
      }
    }
  }

  nextLevel() {
    this.levelManager.nextLevel();
    this.alienManager.reset(this.levelManager.getLevelConfig());
    this.assetManager.reset();
    this.state = GameState.Playing;
    // Hide the text when starting the next level
    this.scoreManager.hideText();
  }

  async restart() {
    try {
      const startReq = await this.blastd.startGame();
      console.log("Enough Lives, Game started successfully");
      this.levelManager.reset(); // Use the new reset method instead of resetLevelParameters
      this.state = GameState.Playing;
      this.player.enableBody(true, this.player.x, this.player.y, true, true);
      this.scoreManager.resetLives();
      this.scoreManager.resetScore();
      this.scoreManager.hideText();
      this.alienManager.reset(this.levelManager.getLevelConfig());
      this.assetManager.reset();
    } catch (error) {
      console.log("Failed to start new game:", error);
    }
  }

  private onPointerDown(pointer: Phaser.Input.Pointer): void {
    if (this.state === GameState.Playing) {
      this.isDragging = true;
      this.lastPointerPosition = new Phaser.Math.Vector2(pointer.x, pointer.y);
      this._fireBullet();
    }
  }

  private onPointerMove(pointer: Phaser.Input.Pointer): void {
    if (this.isDragging && this.lastPointerPosition) {
      const dx = pointer.x - this.lastPointerPosition.x;
      this.player.x += dx;
      this.player.x = Phaser.Math.Clamp(this.player.x, 0, this.scale.width);
      this.lastPointerPosition.set(pointer.x, pointer.y);
    }
  }

  private onPointerUp(): void {
    this.isDragging = false;
    this.lastPointerPosition = null;
  }

  private async handleStateTransition() {
    switch (this.state) {
      case GameState.Win:
        this.nextLevel();
        break;

      case GameState.GameOver:
        try {

          const energyData = await this.blastd.fetchEnergy();
          this.energy = energyData.CurrentEnergy;

          if (this.energy > 0) {
              console.log("User has energy:", this.energy);
              this.scoreManager.removeGameOverText();
              await this.restart();
          } else {
              console.log("User has no energy left");
              this.returnToMainMenu();
          }
      } catch (error) {
          console.error("Error fetching energy:", error);
      }
        break;
    }
  }

  private handleContinueTouch() {
    if (this.state !== GameState.GameOver && this.state !== GameState.Win) return;
    this.handleStateTransition();

    console.log("handleContinueTouch");
  }

  private handleContinueKeyboard() {
    if (this.state !== GameState.GameOver && this.state !== GameState.Win) return;
    this.handleStateTransition();

    console.log("handleContinueKeyboard");
  }

  private getShipAssetType(sprite: string): AssetType {
    switch (sprite) {
      case "player-blue.png":
        return AssetType.ShipBlue;
      case "player-green.png":
        return AssetType.ShipGreen;
      case "player-orange.png":
        return AssetType.ShipOrange;
      case "player-purple.png":
        return AssetType.ShipPurple;
      default:
        return AssetType.Ship;
    }
  }

  returnToMainMenu() {
    // Remove the event listeners
    this.input.off("pointerdown", this.handleContinueTouch, this);
    this.input.keyboard?.off("keydown-SPACE", this.handleContinueKeyboard, this);

    this.scene.stop();
    this.game.events.emit("returnToMainMenu");
  }

  gameOver() {
    if (this.state !== GameState.GameOver) {
      this.state = GameState.GameOver;
      globalEventEmitter.emit("gameOver", this.scoreManager.score);
    }
  }

  private _playerHitPowerup(
    player: Phaser.GameObjects.GameObject,
    powerup: Phaser.GameObjects.GameObject
  ) {
    const typedPowerup = powerup as Powerup;
    this.sound.play(SoundType.Powerup);
    (player as Ship).activateShield();
    typedPowerup.consume();
  }

  private clearEnemyBullets() {
    this.assetManager.enemyBullets.clear(true, true);
  }
}
