import React, { useState, useEffect } from "react";
import { useTonAddress } from "@tonconnect/ui-react";
import { Blastd } from "../../blastd/blastd"; // Adjust the import path as needed
import ReferLink from "../referlink/referLink";
import "./achievement.css";

interface UserStats {
  games: number;
  points: number;
  tasksCompleted: number;
}

interface LeaderboardEntry {
  address: string;
  score: number;
}

interface StoreItem {
  id: number;
  name: string;
  image: string;
  price: number;
}

const Achievement: React.FC = () => {
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [userItems, setUserItems] = useState<Number[]>([]);
  const [storeItems, setStoreItems] = useState<StoreItem[]>([]);
  const [refs, setRefs] = useState<[]>([])
  const userFriendlyAddress = useTonAddress();
  const blastd = Blastd.getInstance();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchRefs();
      await fetchUserStats();
      await fetchLeaderboard();
      if (userFriendlyAddress) {
        await fetchUserItems();
      }
      await fetchStoreItems();
      setIsLoading(false);
    };

    fetchData();
  }, [userFriendlyAddress]);

  const fetchUserStats = async () => {
    try {
      const stats = await blastd.fetchUserStats();
      setUserStats(stats);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  const fetchRefs = async () => {
    try {
      const newRefs = await blastd.fetchRefs();
      setRefs(newRefs);
    } catch (error) {
      console.error("Error fetching refs:", error)
    }
  }

  const fetchLeaderboard = async () => {
    try {
      const leaderboardData = await blastd.fetchLeaderboard();
      setLeaderboard(leaderboardData);
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
    }
  };

  const fetchUserItems = async () => {
    if (!userFriendlyAddress) return;
    try {
      const items = await blastd.getUserItems(userFriendlyAddress);
      setUserItems(items);
    } catch (error) {
      console.error("Error fetching user items:", error);
    }
  };

  const fetchStoreItems = async () => {
    try {
      const items = await blastd.getStoreItems();
      setStoreItems(items);
    } catch (error) {
      console.error("Error fetching store items:", error);
    }
  };

  const getUserOwnedItems = () => {
    if (!storeItems) return [];
    return storeItems.filter(item => userItems.includes(item.id));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="stats-container">
        <div className="stat-container-item">
          <div className="stat-container-item-header">Games Played</div>
          <div className="stat-container-item-value">{userStats?.games || 0}</div>
        </div>
        <div className="stat-container-item">
          <div className="stat-container-item-header">Points</div>
          <div className="stat-container-item-value">{userStats?.points || 0}</div>
        </div>
        <div className="stat-container-item">
          <div className="stat-container-item-header">Tasks Completed</div>
          <div className="stat-container-item-value">{userStats?.tasksCompleted || 0}</div>
        </div>
      </div>

      <div className="assets-container">
        <div className="assets-header">
          <h2>Assets</h2>
          <span className="assets-count">{userItems.length}</span>
        </div>
        <div className="assets-grid">
          {getUserOwnedItems().map(item => (
            <div key={item.id} className="asset-item">
              <img src={`https://cdn.chickenblaster.xyz/assets/images/ui/store/${item.image}`} alt={item.name} />
            </div>
          ))}
        </div>
      </div>

      <ReferLink refs={refs}></ReferLink>

      <div className="leaderboard-container">
        <h2 className="leaderboard-header">Leader board</h2>
        <div className="leaderboard-list">
          {leaderboard.map((entry, index) => (
            <div className="leaderboard-item" key={index.toString()}>
              <img src="https://cdn.chickenblaster.xyz/assets/images/chicken-red.webp" alt="Avatar" className="leaderboard-avatar" />
              <span className="leaderboard-name">{entry.address.substring(entry.address.length-6)}</span>
              <span className="leaderboard-points">
                {entry.score.toLocaleString()} <span className="points-label">points</span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Achievement;