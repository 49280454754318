import TonWeb from 'tonweb';
import { Utils } from "../utils/utils";
import { TonConnectUI } from '@tonconnect/ui';
import { beginCell } from "@ton/ton";
import { StoreItem } from '../components/store/Store';

export class Blastd {
    private static instance: Blastd;
    private isAuthenticated: boolean = false;
    private sessionExpiry: number = 0;

    private payload: string | null = null;
    private payloadExpiry: number = 0;

    private readonly PAYLOAD_TTL: number = 5 * 60 * 1000;

    private readonly RPC_URL: string = 'https://ton-mainnet.core.chainstack.com/8fbeb360d9b428ab8e5e7b07105df8a0/api/v2/jsonRPC';
    private readonly CONTRACT_ADDRESS: string = 'EQArjgGiuTu6rXgsc1KiStAXOFkcT-Rv2e07F_Suk8GyMcOt';

    private tonweb: TonWeb;

    activeShip: number | null = null;
    energy: number | null = null;

    private constructor() {
        this.tonweb = new TonWeb(new TonWeb.HttpProvider(this.RPC_URL));
    }

    public static getInstance(): Blastd {
        if (!Blastd.instance) {
            Blastd.instance = new Blastd();
        }
        return Blastd.instance;
    }

    public isAuthenticatedUser(): boolean {
        return this.isAuthenticated
    }

    public async getPayload(): Promise<string> {
        const now = Date.now();
        if (!this.payload || now > this.payloadExpiry) {
            try {
                const response = await this.makeApiCall(`/payload`);
                if (!response.ok) {
                    throw new Error('Failed to fetch payload from server');
                }
                const data = await response.json();
                this.payload = data.payload;
                this.payloadExpiry = now + this.PAYLOAD_TTL;
            } catch (error) {
                console.error('Error fetching payload:', error);
                throw error;
            }
        }
        return this.payload!;
    }

    private async makeApiCall(url: string, method = 'GET', body: any = null) {
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        };

        // Get stored session data from localStorage
        const storedSessionData = localStorage.getItem('sessionCookie');
        if (storedSessionData) {
            headers['X-Session-Data'] = storedSessionData;
        }

        const options: RequestInit = {
            method: method,
            headers: headers,
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);

        // Check for and store new session data
        const newSessionData = response.headers.get('X-Session-Data');
        if (newSessionData) {
            localStorage.setItem('sessionCookie', newSessionData);
        }

        return response;
    }

    public async verifyProof(address: string, proof: any): Promise<boolean> {
        try {
            const tg = window.Telegram.WebApp;
            const response = await this.makeApiCall(`/login`, 'POST', {
                address: address,
                proof: proof,
                username: tg.initDataUnsafe.user.username,
                telegramid: tg.initDataUnsafe.user.id,
            })
            if (!response.ok) {
                throw new Error('Failed to verify proof with server');
            }
            this.isAuthenticated = true
            return true;
        } catch (error) {
            console.error('Error verifying proof:', error);
            this.isAuthenticated = false
            return false;
        }
    }

    public async verifySession(): Promise<boolean> {

        if (this.isAuthenticated && Date.now() < this.sessionExpiry) {
            return true;
        }

        try {
            const response = await this.makeApiCall(`/verifysession`);
            if (response.ok) {
                this.isAuthenticated = true
                this.sessionExpiry = Date.now() + 60 * 60 * 1000; // TODO (return expiru from server)
                return true;
            } else if (response.status === 403) {
                this.isAuthenticated = false
                return false;
            }

            throw new Error('Failed to verify session with server');
        } catch (error) {
            console.error('Error verifying session:', error);
            this.isAuthenticated = false
            return false;

        }
    }

    // Logged IN Functions

    public async fetchTotalPoints(): Promise<number> {
        try {
            const response = await this.makeApiCall("/v1/totalpoints");
            if (!response.ok) {
                throw new Error('Failed to fetch total points from server');
            }
            const data = await response.json();
            return data.points;
        } catch (error) {
            console.error('Error fetching total points:', error);
            throw error;
        }
    }

    public async fetchEnergy(): Promise<any> {
        try {
            const response = await this.makeApiCall(`/v1/energy`);
            if (!response.ok) {
                throw new Error('Failed to fetch energy from server');
            }
            const data = await response.json();
            this.energy = data.CurrentEnergy;
            return data;
        } catch (error) {
            console.error('Error fetching energy:', error);
            throw error;
        }
    }

    public async startGame(): Promise<any> {
        try {
            const response = await this.makeApiCall(`/v1/startgame`);
            if (!response.ok) {
                throw new Error('Failed to start game with server');
            }
            return await response.json();
        } catch (error) {
            console.error('Error start game:', error);
            throw error;
        }
    }

    public async submitScore(score: number): Promise<void> {
        try {
            const response = await this.makeApiCall(`/v1/score`, 'POST', {
                score: score,
            });
            if (!response.ok) {
                throw new Error('Failed to submit score to server');
            }
        } catch (error) {
            console.error('Error submitting score:', error);
            throw error;
        }
    }

    public async getActiveShip(): Promise<number> {
        if (this.activeShip != null) {
            return this.activeShip;
        }

        try {
            const response = await this.makeApiCall(`/v1/getactiveship`);
            if (!response.ok) {
                throw new Error('Failed to fetch active ship from server');
            }
            const data = await response.json();
            this.activeShip = data;
            return this.activeShip!!;
        } catch (error) {
            console.error('Error fetching active ship:', error);
            throw error;
        }
    }

    public async setActiveShip(ship: number): Promise<void> {
        try {
            const response = await this.makeApiCall(`/v1/setactiveship`, "POST", {
                id: ship,
            });
            if (!response.ok) {
                throw new Error('Failed to set active ship on server');
            }
            this.activeShip = ship;
        } catch (error) {
            console.error('Error setting active ship:', error);
            throw error;
        }
    }

    public async fetchRefs(): Promise<any> {
        try {
            const response = await this.makeApiCall(`/v1/refs`);
            if (!response.ok) {
                throw new Error('Failed to fetch user stats from server');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching user stats:', error);
            throw error;
        }
    }

    // Games Played
    // Total Points
    // Tasks Completed

    public async fetchUserStats(): Promise<any> {
        try {
            const response = await this.makeApiCall(`/v1/stats`);
            if (!response.ok) {
                throw new Error('Failed to fetch user stats from server');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching user stats:', error);
            throw error;
        }
    }

    // []UserStats
    public async fetchLeaderboard(): Promise<any> {
        try {
            const response = await this.makeApiCall(`/v1/leaderboard`);
            if (!response.ok) {
                throw new Error('Failed to fetch leaderboard from server');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            throw error;
        }
    }

    public async getStoreItems(): Promise<any[]> {
        try {
            const response = await this.makeApiCall(`/items`);
            if (!response.ok) {
                throw new Error('Failed to fetch store items from server');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching store items:', error);
            throw error;
        }
    }

    public async getCompletedTasks(): Promise<Number[]> {
        try {
            const response = await this.makeApiCall(`/v1/completedtasks`);
            if (!response.ok) {
                throw new Error('Failed to fetch completed tasks from server');
            }
            const data = await response.json();
            return data;
        }catch(error) {
            console.error('Error fetching completed tasks:', error);
            throw error;
        }
    }

    public async setTaskCompleted(id: number): Promise<void> {
        try {
            const response = await this.makeApiCall(`/v1/settaskcompleted`, 'POST', {
                id: id,
            });
            if (!response.ok) {
                throw new Error('Failed to complete task on server');
            }
        } catch (error) {
            console.error('Error completing task:', error);
            throw error;
        }
    }

    // RPC Functions

    public async checkUserItem(userAddress: string, itemId: number): Promise<boolean> {
        try {
            const addrSlice = await Utils.addressToTvmSlice(userAddress);

            const result = await this.tonweb.provider.call(
                this.CONTRACT_ADDRESS,
                'hasItem',
                [
                    ['tvm.Slice', addrSlice],
                    ['num', itemId.toString()]
                ]
            );

            // Assuming the contract method returns 1 for true and 0 for false
            return BigInt(result.stack[0][1]).toString() === '1';
        } catch (error) {
            console.error('Error checking user item:', error);
            throw error;
        }
    }

    public async getItemPrice(itemId: number): Promise<BigInt> {
        try {
            const tonweb = new TonWeb(new TonWeb.HttpProvider(this.RPC_URL));

            const result = await tonweb.provider.call(
                this.CONTRACT_ADDRESS,
                'itemPrice',
                [
                    ['num', itemId.toString()]
                ]
            );

            // Assuming the contract method returns 1 for true and 0 for false
            return BigInt(result.stack[0][1]);
        } catch (error) {
            console.error('Error checking user item:', error);
            throw error;
        }
    }

    public async getUserItems(userAddress: string): Promise<Array<Number>> {
        try {
            const addrSlice = await Utils.addressToTvmSlice(userAddress);

            const result = await this.tonweb.provider.call(
                this.CONTRACT_ADDRESS,
                'getUserItems',
                [
                    ['tvm.Slice', addrSlice],
                ]
            );

            const data = TonWeb.utils.base64toString(result.stack[0][1].object.data.b64).split(',').map(Number);
            data.pop();

            return data;
        } catch (error) {
            console.error('Error checking user item:', error);
            throw error;
        }
    }

    public async getUserPaidStatus(address: string): Promise<boolean> {
        try {
            const addrSlice = await Utils.addressToTvmSlice(address);

            const result = await this.tonweb.provider.call(
                this.CONTRACT_ADDRESS,
                "userPaid",
                [["tvm.Slice", addrSlice]]
            );
            if (BigInt(result.stack[0][1]).toString().match("1")) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error checking user item:', error);
            throw error;
        }
    }

    public async makePayment(tonConnect: TonConnectUI) {
        try {
            const body = beginCell()
                .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                .storeStringTail("MakePayment") // write our text comment
                .endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // Valid for 60 seconds
                messages: [
                    {
                        address: this.CONTRACT_ADDRESS,
                        amount: "200000000", // 0.1 TON in nanotons
                        payload: body.toBoc().toString("base64"),
                    },
                ],
            };

            const result = await tonConnect.sendTransaction(transaction);
            console.log("Transaction sent successfully", result);
        } catch (error) {
            console.error("Transaction failed", error);
            throw error;
        }
    }

    public async buyItem(tonConnect: TonConnectUI, item: StoreItem) {
        try {

            const body = beginCell()
                .storeUint(719771380, 32) // op code
                .storeUint(item.id, 32)
                .endCell();

            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // Valid for 60 seconds
                messages: [
                    {
                        address: this.CONTRACT_ADDRESS,
                        amount: item.price.toString(),
                        payload: body.toBoc().toString("base64"),
                    },
                ],
            };

            const result = await tonConnect.sendTransaction(transaction);
            console.log("Transaction sent successfully", result);

        } catch (error) {
            console.error("Transaction failed", error);
            throw error;
        }
    }

    public async waitForPayment(
        address: string
    ): Promise<boolean> {
        // wait for payment
        var i = 20
        while(i >= 0) {
            const paid = await this.getUserPaidStatus(address)
            if (paid) {
                return true;
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
            i--;
        }
        return false; // Max attempts reached, transaction not confirmed
    }

}
