import { Scene } from 'phaser';
import { AssetType, SoundType } from '../interface/assets';

export class PreloadScene extends Scene {
  constructor() {
    super({ key: 'PreloadScene' });
  }

  preload() {
    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    // Create loading bar
    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    
    const barWidth = 320;
    const barHeight = 50;
    const barX = (width - barWidth) / 2;
    const barY = height / 2 - 25;
    
    progressBox.fillRect(barX, barY, barWidth, barHeight);

    // Create loading text
    const loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 70,
      text: 'Loading...',
      style: {
        font: '20px monospace',
        color: '#ffffff'
      }
    });
    loadingText.setOrigin(0.5, 0.5);

    // Create percent text
    const percentText = this.make.text({
      x: width / 2,
      y: height / 2,
      text: '0%',
      style: {
        font: '18px monospace',
        color: '#ffffff'
      }
    });
    percentText.setOrigin(0.5, 0.5);

    // Update progress bar
    this.load.on('progress', (value: number) => {
      percentText.setText(parseInt(String(value * 100)) + '%');
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(barX + 10, barY + 10, (barWidth - 20) * value, barHeight - 20);
    });

    // Remove progress bar when loading complete
    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
    });

    // Load assets
    this.load.setBaseURL("https://cdn.chickenblaster.xyz/assets");
    this.load.image(AssetType.Starfield, "images/starfield.png");
    this.load.image(AssetType.Bullet, "images/bullet.png");
    this.load.image(AssetType.EnemyBullet, "images/enemy-bullet.png");
    this.load.image(AssetType.Alien, "images/chicken.webp");
    this.load.image(AssetType.RedAlien, "images/chicken-red.webp");
    this.load.image(AssetType.Powerup, "images/powerup.png");

    // Load all ship sprites
    this.load.image(AssetType.Ship, "images/player.png");
    this.load.image(AssetType.ShipBlue, "images/player-blue.png");
    this.load.image(AssetType.ShipGreen, "images/player-green.png");
    this.load.image(AssetType.ShipOrange, "images/player-orange.png");
    this.load.image(AssetType.ShipPurple, "images/player-purple.png");

    this.load.spritesheet(AssetType.Kaboom, "images/explode.png", {
      frameWidth: 128,
      frameHeight: 128,
    });

    this.load.audio(SoundType.Shoot, "audio/shoot.wav");
    this.load.audio(SoundType.Kaboom, "audio/explosion.wav");
    this.load.audio(SoundType.InvaderKilled, "audio/invaderkilled.wav");
    this.load.audio(SoundType.Hit, "audio/hit.mp3");
    this.load.audio(SoundType.Powerup, "audio/powerup.mp3");
    this.load.image(AssetType.Shield, "images/shield.png");
  }

  create() {
    this.scene.start('MainScene');
  }
}
